<template>
  <div style="overflow:initial;">
    <v-navigation-drawer
      v-model="drawer"
      floating
      color="light-blue darken-4"
      dark
      app
    >
      <div class="text-center mt-2">
        <img
          src="@/assets/logowhite_oneboxbusiness-horizontal.png"
          width="38%"
          height="38%"
        />
      </div>
      <v-list dense nav>
        <div class="mt-2 mb-2">
          <v-autocomplete
            @change="changestorage_sidebar(choosetypeuser)"
            :items="typeuser"
            :item-text="$t('default') === 'en' ? 'nameen' : 'nameth'"
            :color="color.theme"
            filled
            v-model="choosetypeuser"
            return-object
            dense
            hide-details
            rounded
            class="rounded-lg"
          >
            <template color="#F6F7FA" v-slot:item="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 14px; ">{{
                item.nameen
              }}</span>
              <span v-else style="font-size: 14px;">{{ item.nameth }}</span>
            </template>
            <template color="#F6F7FA" v-slot:selection="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 13px; ">{{
                item.nameen
              }}</span>
              <span v-else style="font-size: 13px;">{{ item.nameth }}</span>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>
        <template v-for="item in listmenu()">
          <v-row
            :color="color.theme"
            v-if="item.heading"
            :key="item.heading"
            align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            :color="color.theme"
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
          >
            <template v-slot:activator>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              class="pl-10"
              v-for="(child, i) in item.children"
              :key="i"
              link
              @click="tolink(child.link, child.text)"
            >
              <v-list-item-action>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t(child.text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            :color="color.theme"
            v-else
            :to="item.disable === true ? '' : item.link"
            :disabled="item.disable"
            :key="item.text"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
            <v-expand-transition>
              <v-overlay
                v-if="item.disable === true"
                absolute
                color="grey lighten-1"
                :value="disableByPackage"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-lock</v-icon>
                  </template>
                  <span>{{ $t("balloon.lock_function") }}</span>
                </v-tooltip>
              </v-overlay>
            </v-expand-transition>
          </v-list-item>
        </template>
        <v-divider></v-divider>
        <v-subheader>{{ $t("forbusiness") }}</v-subheader>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            color="white"
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            prepend-icon
            :append-icon="item.model ? item.icon : item['icon-alt']"
          >
            <template v-slot:activator>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              @click="tolink(child.link, child.text)"
            >
              <v-list-item-action>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t(child.text) }}</v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action v-if="child.icon">
                    <v-icon></v-icon>
                </v-list-item-action>-->
            </v-list-item>
          </v-list-group>
          <!-- <div v-else :key="item.text"> -->
          <v-list-item
            v-else
            :to="item.link"
            :disabled="item.disable"
            :key="item.text"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
          </v-list-item>
          <!-- </div> -->
        </template>
      </v-list>
      <div v-if="dataBusinessProfile.length > 0">
        <v-divider></v-divider>
      </div>
      <template v-slot:append>
        <div>
          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>storage</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("toolbar.storage")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">
                  <v-progress-linear
                    class="mt-2"
                    color="white"
                    rounded
                    :value="percentageStorage"
                  ></v-progress-linear>
                  <br />
                  {{ dataStorageUsage }} in {{ dataStorageMax }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar class="elevation-0" outlined app color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-divider inset vertical></v-divider>

      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn text x-large v-on="on">
            <v-icon class="mr-2" large color="blue-grey darken-4"
              >account_circle</v-icon
            >
            <div class="hidden-sm-and-down text-left">
              <b style="color: #263238">{{ fullname }}</b>
              <br />
              <span style="font-size: 13px; color: #263238">{{
                $t("businessaccount")
              }}</span>
            </div>
          </v-btn>
        </template>
        <v-card>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content class="text-left">
                <v-list-item-title
                  ><B style="font-size: 17px">{{
                    fullname
                  }}</B></v-list-item-title
                >
                <v-list-item-subtitle>{{
                  $t("businessaccount")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <img
                  src="https://image.flaticon.com/icons/svg/149/149071.svg"
                  alt="John"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-card-text class="text-center">
            <v-btn color="error" to="/business/logout" outlined class="pa-3"
              ><b>{{ $t("logout") }}</b></v-btn
            >
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab text v-on="on" small>
            <flag :iso="defaultflag" :squared="false" />
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item
            v-for="(item, i) in language"
            :key="i"
            @click="changelang(item.data)"
          >
            <v-list-item-action>
              <flag :iso="item.flag" :squared="false" />
            </v-list-item-action>
            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <uploadfiles
      :show="openUploadfiles"
      :parentfolder="parentfolder"
      @close="closeDialog(), (openUploadfiles = false), $emit('callstorage')"
      @openuploadfile="openUploadfiles = true"
    ></uploadfiles>
    <uploadfolder
      :show="openUploadfolder"
      :parentfolder="parentfolder"
      @close="closeDialog(), (openUploadfolder = false)"
      @openuploadfolder="openUploadfolder = true"
    ></uploadfolder>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
const uploadfolder = () => import("../upload/dialog-uploadfolder");
const uploadfiles = () => import("../upload/dialog-uploadfiles2");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["parentfolder", "activemenu"],
  components: { uploadfolder, uploadfiles },
  data: () => ({
    forbusiness: [
      {
        active: true,
        icon: "mdi-inbox-arrow-down",
        text: "toolbar.allbusinessinbox",
        link: "/business/allinbox-new",
        disable: false,
      },
      {
        active: true,
        icon: "mdi-inbox-arrow-up",
        text: "toolbar.allbusinessoutbox",
        link: "/business/alloutbox-new",
        disable: false,
      },
    ],
    counter: 0,
    defaultaccount: "ผู้ใช้งานทั่วไป",
    defaultflag: "",
    typeuser: [],
    choosetypeuser: {},
    language: [
      {
        flag: "us",
        name: "languages.english",
        data: "en",
      },
      {
        flag: "th",
        name: "languages.thai",
        data: "th",
      },
    ],
    dialog: false,
    drawer: null,
    tabs: 2,
    openUploadfiles: false,
    openUploadfolder: false,
    items: [
      {
        active: true,
        icon: "mdi-inbox-arrow-down",
        text: "toolbar.allbusinessinbox",
        link: "/allinbox-new",
        disable: false,
      },
      {
        active: true,
        icon: "mdi-inbox-arrow-up",
        text: "toolbar.allbusinessoutbox",
        link: "/alloutbox-new",
        disable: false,
      },
    ],
  }),
  created() {
    this.changelang(localStorage.getItem("lang"));
    this.dataTypeUser();
  },
  filters: {
    subStr: function(val) {
      if (val.length > 22) return val.substring(0, 22) + "...";
      else return val;
    },
  },
  computed: {
    ...mapState([
      "color",
      "status_permission_outbox",
      "status_permission_inbox",
      "feature_status",
      "role_level",
      "storage_usage",
      "storage_usage_biz",
      "storage_max_personal_biz",
    ]),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
    }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),

    checkroutername() {
      return this.$router.app["_route"]["name"] || "";
    },

    percentageStorage() {
      let _dataStorageMax = this.dataStorageMax.split(" ");
      let _dataStorageUsage = this.dataStorageUsage.split(" ");

      if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "MB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        return Math.ceil(total);
      } else if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "KB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        return Math.ceil(total);
      } else {
        let max_storage = parseFloat(_dataStorageMax[0]);
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        return Math.ceil(total);
      }
    },

    fullname() {
      if (this.$t("default") === "th") {
        return (
          this.$store.getters.dataCitizenProfile["first_name_th"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_th"] || ""
        );
      } else {
        return (
          this.$store.getters.dataCitizenProfile["first_name_th"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_th"] || ""
        );
      }
    },
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    dataTypeUser() {
      let _citizen = {
        type: "Citizen",
        data: [],
        nameth: "ผู้ใช้งานทั่วไป",
        nameen: "Personal",
        taxid: "",
      };

      this.typeuser.push(_citizen);

      let i = 0;
      for (i = 0; i < this.dataBusinessProfile.length; i++) {
        this.typeuser.push({
          type: "Business",
          data: this.dataBusinessProfile[i],
          nameth: this.dataBusinessProfile[i]["first_name_th"],
          nameen: this.dataBusinessProfile[i]["first_name_eng"],
          taxid: this.dataBusinessProfile[i]["id_card_num"],
        });
      }

      if (this.dataAccountActive["type"] === "Business") {
        let type_business_index = this.typeuser.findIndex(
          (x) => x.taxid === this.dataAccountActive["taxid"]
        );
        this.choosetypeuser = this.typeuser[type_business_index];
      } else {
        this.choosetypeuser = {
          type: "Citizen",
          data: [],
          nameth: "ผู้ใช้งานทั่วไป",
          nameen: "Personal",
        };
      }
    },
    changestorage_sidebar(_choosetypeuser) {
      let beforechoose = this.choosetypeuser;
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      if (_choosetypeuser.type === "Citizen") {
        this.$store.dispatch("switchaccount_citizen").then((msg) => {
          this.$emit("loadfile");
          this.$emit("callstorage");
          this.$router.push({ name: "myfiles" });
        });
      } else {
        if (this.$store.state.service !== "OneMail") {
          let payload = {
            taxid: _choosetypeuser.data.id_card_num,
            accesstoken: this.dataAccesstoken,
          };
          this.$store
            .dispatch("switchaccount_business", payload)
            .then((msg) => {
              // console.log(msg);

              if (msg["status"] === "Business Access Denied") {
                if (msg["msg"] === "ER401: Authorization is wrong") {
                  this.$router.push("logout");
                } else {
                  if (this.dataAccountActive["type"] === "Business") {
                    let type_business_index = this.typeuser.findIndex(
                      (x) => x.taxid === this.dataAccountActive["taxid"]
                    );
                    this.choosetypeuser = this.typeuser[type_business_index];
                  } else {
                    this.choosetypeuser = {
                      type: "Citizen",
                      data: [],
                      nameth: "ผู้ใช้งานทั่วไป",
                      nameen: "Personal",
                    };
                  }
                  Toast.fire({ icon: "error", title: msg["msg"] });
                  this.$emit("loadfile");
                  this.$emit("callstorage");
                  this.$router.push({ name: "myfiles" });
                  // this.$store.dispatch("switchaccount_citizen").then(msg => {
                  //   this.$emit("loadfile");
                  //   this.$emit("callstorage");
                  //   this.$router.push({ name: "myfiles" });
                  // });
                }
              } else {
                this.choosetypeuser = _choosetypeuser;
                // console.log(msg);
                this.$emit("loadfile");
                this.$emit("callstorage");
                this.$router.push({ name: "myfiles" });
              }
            });
        }
      }
    },
    changestorage(typeuser, business) {
      if (typeuser === "Citizen") {
        this.$store.dispatch("switchaccount_citizen").then((msg) => {
          this.$emit("loadfile");
          this.$emit("callstorage");
          this.$router.push({ name: "myfiles" });
        });
      } else {
        if (this.$store.state.service !== "OneMail") {
          let payload = {
            taxid: business.id_card_num,
            accesstoken: this.dataAccesstoken,
          };
          this.$store
            .dispatch("switchaccount_business", payload)
            .then((msg) => {
              // console.log(msg);
              if (msg["status"] === "Business Access Denied") {
                if (msg["msg"] === "ER401: Authorization is wrong") {
                  this.$router.push("logout");
                } else {
                  Toast.fire({ icon: "error", title: msg["msg"] });
                }
              } else {
                // console.log(msg);
                this.$emit("loadfile");
                this.$emit("callstorage");
                this.$router.push({ name: "myfiles" });
              }
            });
        }
      }
    },
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
    tolink(parameter, text) {
      console.log("parameter",parameter);
      console.log("text",text);
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      if (text === "toolbar.myinbox.Personalname") {
        this.$router.push({ path: parameter, query: { type: 1 } });
      } else if (text === "toolbar.myinbox.Businessname") {
        this.$router.push({ path: parameter, query: { type: 2 } });
      } else if (text === "toolbar.myoutbox.Businessname") {
        this.$router.push({ path: parameter, query: { type: 2 } });
      } else if (text === "toolbar.myoutbox.Personalname") {
        this.$router.push({ path: parameter, query: { type: 1 } });
      } else {
        this.$router.replace(parameter);
      }
    },
    closeDialog() {
      this.openUploadfiles = false;
      this.openUploadfolder = false;
      this.$emit("loadfile");
    },
    listmenu() {
      //  console.log("admin",this.role_level);
      // console.log("feature_status",this.feature_status);
      let _items = [];
      // console.log("type",this.dataAccountActive["type"]);
      // console.log("permissioninout",this.status_permission);

    //   if (this.dataAccountActive["type"] === "Business") {
        if (
          this.status_permission_inbox === "DE" &&
          this.status_permission_outbox === "AL"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              color: this.color.theme || "primary",
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
              ],
            },

          ];
        } else if (
          this.status_permission_inbox === "DE" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "mdi-delete-sweep-outline",
                  text: "toolbar.myoutbox.Trashoutbox",
                  link: "/trashoutbox",
                  type: 3,
                  color: this.color.theme || "primary",
                },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "DE" &&
          this.status_permission_outbox === "DE"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myoutbox.Businessname", //////
                //   link: "/myoutbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "AL" &&
          this.status_permission_outbox === "DE"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myoutbox.Businessname", //////
                //   link: "/myoutbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === "DE"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              color: this.color.theme || "primary",
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "mdi-delete-sweep-outline",
                  text: "toolbar.myinbox.Trashinbox",
                  link: "/trashinbox",
                  type: 3,
                  color: this.color.theme || "primary",
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              color: this.color.theme || "primary",
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myoutbox.Businessname", //////
                //   link: "/myoutbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "mdi-delete-sweep-outline",
                  text: "toolbar.myinbox.Trashinbox",
                  link: "/trashinbox",
                  type: 3,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "date_range",
                //   text: "ไฟล์อินบ๊อกซ์หมดอายุ",
                //   link: "/fileexpiredinbox",
                //   type: 4,
                // },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // outbox123
                {
                  icon: "mdi-delete-sweep-outline",
                  text: "toolbar.myoutbox.Trashoutbox",
                  link: "/trashoutbox",
                  type: 3,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "date_range",
                //   text: "ไฟลเอาท์บ๊อกซ์หมดอายุ",
                //   link: "/fileexpiredoutbox",
                //   type: 4,
                // },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "AL" &&
          this.status_permission_outbox === "AL"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              color: this.color.theme || "primary",
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  color: this.color.theme || "primary",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3
                // }
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === "AL"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              color: this.color.theme || "primary",
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "mdi-delete-sweep-outline",
                  text: "toolbar.myinbox.Trashinbox",
                  link: "/trashinbox",
                  type: 3,
                  color: this.color.theme || "primary",
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3
                // }
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "AL" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "mdi-delete-sweep-outline",
                  text: "toolbar.myoutbox.Trashoutbox",
                  link: "/trashoutbox",
                  type: 3,
                  color: this.color.theme || "primary",
                },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "AL" &&
          this.status_permission_outbox === ""
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              color: this.color.theme || "primary",
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === ""
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              color: this.color.theme || "primary",
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "mdi-delete-sweep-outline",
                  text: "toolbar.myinbox.Trashinbox",
                  link: "/trashinbox",
                  type: 3,
                  color: this.color.theme || "primary",
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "" &&
          this.status_permission_outbox === "AL"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              color: this.color.theme || "primary",
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
          ];
        } else if (
          this.status_permission_inbox === "" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "mdi-delete-sweep-outline",
                  text: "toolbar.myoutbox.Trashoutbox",
                  link: "/trashoutbox",
                  type: 3,
                  color: this.color.theme || "primary",
                },
              ],
            },
          ];
        } else {
          _items = [
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              color: this.color.theme || "primary",
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutboxetax",
                  type: 1,
                  color: this.color.theme || "primary",
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutboxetax",
                  type: 2,
                  color: this.color.theme || "primary",
                },
                // {
                //   icon: "mdi-delete-sweep-outline",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
          ];
        }
    //   } 
      let filterFeature = [];
      console.log("this.feature_status",this.feature_status);
    //   if (this.feature_status !== undefined) {
    //     console.log("เข้า  ===>");
    //     filterFeature = _items.filter(this.checkfeature);
    //     console.log("new1",filterFeature);
    //   }
      return _items;
    },
  },
  mounted() {},
};
</script>
